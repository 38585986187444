import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../../Layouts/Layout'

const AdventuresIndexPage = () => {
  return <Layout />
}

AdventuresIndexPage.propTypes = {}

export default AdventuresIndexPage
